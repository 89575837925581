import { DateFormatV2 } from '@agroone/dates'
import { IsDateValidate } from '@agroone/helpers'
import { IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'

export class PatchOfferDto {
  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  groupCode?: string | null

  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  confirmedHarvestDate?: string | null

  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  lastCheckOutSiteDate?: string | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  status?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  durationLots?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  harvestTeamId?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  cadence?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  speed?: number | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  startHarvestDate?: Date | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  replaceOfferId?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  droppedAfterOfferId?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  factoryId?: number | null

  @IsString()
  @IsDateValidate(DateFormatV2.SIMPLE)
  @IsOptional()
  yesterdayDate?: string
}
