import {
  WebSocketEditNotification,
  WebSocketEditNotificationElement,
  WebSocketEditNotificationStatus,
} from '@agroone/entities'
import { Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class WebSocketStateService {
  public readonly elementsSubject = new BehaviorSubject<WebSocketEditNotification[]>([])
  public readonly elements$ = this.elementsSubject.asObservable()

  setEditingStatus(notification: WebSocketEditNotification): void {
    const currentElements = this.elementsSubject.getValue()
    const updatedElements = currentElements.map((element) =>
      element.elementId === notification.elementId && element.elementType === notification.elementType
        ? { ...element, status: notification.status }
        : element
    )

    if (
      !currentElements.some(
        (element) => element.elementId === notification.elementId && element.elementType === notification.elementType
      )
    ) {
      updatedElements.push(notification)
    }

    this.elementsSubject.next(updatedElements)
  }

  isEditing(elementId: string, elementType: WebSocketEditNotificationElement): Observable<boolean> {
    return this.elementsSubject.asObservable().pipe(
      map((elements) => {
        const element = elements.find(
          (element) => element.elementId === elementId && element.elementType === elementType
        )
        return element ? element.status === WebSocketEditNotificationStatus.EDITING : false
      })
    )
  }
}
