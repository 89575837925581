import { LoggerService, SharedUserService, WebSocketStateService } from '@agroone-front/shared'
import {
  WebSocketEditNotification,
  WebSocketEditNotificationElement,
  WebSocketEditNotificationStatus,
} from '@agroone/entities'
import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { io, Socket } from 'socket.io-client'
import { API_URL } from '../models/environment.token'

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket
  private apiUrl: string = inject(API_URL)

  constructor(
    private userService: SharedUserService,
    private logger: LoggerService,
    private webSocketStateService: WebSocketStateService
  ) {
    this.connect()

    // Close the WebSocket when the user closes the page
    window.onbeforeunload = () => {
      this.disconnect()
    }

    window.addEventListener('offline', () => {
      this.logger.warn('🚨 Network problem detected')
      this.socket.emit('offline')
    })

    window.addEventListener('online', () => {
      this.logger.info('✅ Network restored')
      this.socket.emit('online')
    })
  }

  private connect(): void {
    this.socket = io(this.apiUrl, {
      transports: ['websocket'],
    })

    this.socket.on('connect', () => {
      this.logger.info('WebSocket connected')
    })

    this.socket.on('disconnect', (reason) => {
      this.logger.error('WebSocket disconnected:', reason)
    })

    this.socket.on(WebSocketEditNotificationStatus.EDITING, (data: WebSocketEditNotification) => {
      this.logger.info('Received WebSocket editing message:', data)
      this.webSocketStateService.setEditingStatus(data)
    })

    this.socket.on(WebSocketEditNotificationStatus.AVAILABLE, (data: WebSocketEditNotification) => {
      this.logger.info('Received WebSocket available message:', data)
      this.webSocketStateService.setEditingStatus(data)
    })

    this.socket.on(WebSocketEditNotificationStatus.GET_EDITING_ELEMENTS, (data: WebSocketEditNotification[]) => {
      this.logger.info('Received WebSocket get editing elements message:', data)
      data.forEach((element) => {
        this.webSocketStateService.setEditingStatus(element)
      })
    })

    this.socket.on('error', (error) => {
      this.logger.error('WebSocket error:', error)
    })
  }

  private disconnect(): void {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  private send(data: WebSocketEditNotification): void {
    this.socket.emit(data.status, data)
  }

  public notifyEditStart(elementId: string, elementType: WebSocketEditNotificationElement) {
    const notification: WebSocketEditNotification = {
      status: WebSocketEditNotificationStatus.EDITING,
      elementId,
      elementType,
    }

    this.send(notification)
  }

  public notifyEditEnd(elementId: string, elementType: WebSocketEditNotificationElement) {
    const notification: WebSocketEditNotification = {
      status: WebSocketEditNotificationStatus.AVAILABLE,
      elementId,
      elementType,
    }

    this.send(notification)
  }
}
