import { FrontAppEnvironment } from '../entities/src/lib/dtos/environment'
import { environment as environmentPrd } from './environment.prd'

export const environment: FrontAppEnvironment = {
  ...environmentPrd,
  agrooneUrl: 'https://agroone.cldqlf.bonduelle.com',
  apiUrl: 'https://apiv2.cldqlf.bonduelle.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  dynatraceScriptSrc: '',
  envName: 'qlf',
  agropilotUrl: 'https://agropilot.cldqlf.bonduelle.com/',
  headerColorEnd: '#ffcc80',
  headerColorStart: '#ffa726',
  logLevel: 'DEBUG',
  masterdataUrl: 'https://masterdata.cldqlf.bonduelle.com/',
  qlf: true,
  serverLogLevel: 'DEBUG',
  webSocketUrl: 'wss://ws.apiv2.cldqlf.bonduelle.com',
}
