import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { WebSocketStateService } from '../services'
import { WebSocketEditNotificationElement } from '@agroone/entities'

@Directive({
  selector: '[wsEditing]',
  standalone: true,
})
@UntilDestroy()
export class WebSocketEditingDirective implements OnInit {
  @Input() wsEditing: { elementId: number; elementType: WebSocketEditNotificationElement; classes: string } | undefined

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private webSocketStateService: WebSocketStateService
  ) {}

  ngOnInit(): void {
    this.webSocketStateService
      .isEditing(this.wsEditing.elementId.toString(), this.wsEditing.elementType)
      .pipe(untilDestroyed(this))
      .subscribe((isEditing) => {
        this.updateStyles(isEditing)
      })
  }

  private updateStyles(isEditing: boolean | undefined): void {
    if (isEditing) {
      this.wsEditing.classes.split(' ').forEach((className) => {
        this.renderer.addClass(this.el.nativeElement, className)
      })
    } else {
      this.wsEditing.classes.split(' ').forEach((className) => {
        this.renderer.removeClass(this.el.nativeElement, className)
      })
    }
  }
}
