import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator'

export class BreakDto {
  @IsInt()
  @IsOptional()
  @IsPositive()
  id?: number

  @IsString()
  @IsNotEmpty()
  startDate: string

  @IsString()
  @IsNotEmpty()
  endDate: string

  @IsString()
  @IsOptional()
  comment: string
}
