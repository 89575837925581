import { IsNumber, IsPositive, ValidateIf } from 'class-validator'

import { IsOptional } from 'class-validator'

export class PatchLotV2Dto {
  @IsNumber()
  @IsPositive()
  @IsOptional()
  truckId?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  quantity?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  cadency?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  truckSpeed?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  stockId?: number | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  startHarvestDateReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  endHarvestDateReal?: string | null
}
