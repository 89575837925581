import { NgIf } from '@angular/common'
import { Component } from '@angular/core'

import { TranslateModule } from '@ngx-translate/core'
@Component({
  selector: 'app-new-version-popup',
  templateUrl: './new-version-popup.component.html',
  imports: [TranslateModule, NgIf],
  standalone: true,
})
export class NewVersionPopupComponent {
  public isUpdateInProgress: boolean = false

  reload() {
    this.isUpdateInProgress = true
    window.location.reload()
  }
}
