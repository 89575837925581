import {
  API_URL,
  AuthService,
  ErrorInterceptor,
  LoggerService,
  LoginComponent,
  MonitoringService,
  SharedConstantService,
  SharedLogoutComponent,
  SharedPermissionService,
  SharedUserService,
  ToastService,
  VersionService,
  WithCredentialsInterceptor,
} from '@agroone-front/shared'
import { DateFormatV2, formatDateV2 } from '@agroone/dates'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule } from '@angular/core'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '@front-app-environments/environment'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { subDays } from 'date-fns'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
import { firstValueFrom } from 'rxjs'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { CroptypeService } from './core/services/croptype/croptype.service'
import { DirectionsNetworkService } from './core/services/directions/directions.network.service'
import { FilterService } from './core/services/filter/filter.service'
import { PermissionService } from './core/services/permissions/permission.service'
import { RegionService } from './core/services/region/region.service'
import { SettingsService } from './core/services/settings/settings.service'
import { TechniciansService } from './core/services/technicians/technicians.service'
import { UserService } from './core/services/user/user.service'
import { WebSocketsService } from './core/services/websockets/websockets.service'
import { TranslateLocalIdFactory } from './core/translate/classes/translate-local-id-factory'
import { ConstantService } from './shared/constant/constant.service'
import { HarvestOfferService } from './shared/crop/services/offer.service'
import { LoadingScreenModule } from './shared/loading-screen/loading-screen.module'
import { OfferService } from './shared/v2/crop/services/offer.service'
import { TranslationsLoader } from './translations.loader'
import { PriorityService } from './features/v2/services/priority.service'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogService } from 'primeng/dynamicdialog'

export const init =
  (
    userService: SharedUserService,
    filterService: FilterService,
    regionService: RegionService,
    croptypeService: CroptypeService,
    settingsService: SettingsService,
    constantService: SharedConstantService,
    directionsService: DirectionsNetworkService,
    offerService: OfferService,
    webSocketSerivce: WebSocketsService,
    loggerService: LoggerService,
    authService: AuthService,
    priorityService: PriorityService
  ) =>
  async () => {
    // todo: remove the initialization lines below after migrating them to libs/
    loggerService.init(environment.logLevel, environment.serverLogLevel)
    priorityService.init()
    authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.apiUrl)

    try {
      await firstValueFrom(userService.init())
      // Init User
      // connect to websocket
      webSocketSerivce.connect()

      // Init constant
      constantService.init()

      // Init filter
      filterService.init()

      // Init region
      await firstValueFrom(regionService.init())

      // Init croptypes
      await firstValueFrom(croptypeService.init())

      // Init Settings
      await settingsService.init()

      // Get Direction token
      await firstValueFrom(directionsService.retrieveToken())

      await firstValueFrom(
        offerService.resetPriorisation({
          date: formatDateV2(subDays(new Date(), 10), DateFormatV2.SIMPLE),
        })
      )
    } catch (e) {
      console.error('App Initialisation failed due to', e)
    }
  }

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoginComponent,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CommonModule,
    CoreModule,
    HttpClientModule,
    SharedLogoutComponent,
    MatProgressSpinnerModule,
    MatDialogModule,
    AppRoutingModule,
    LoadingScreenModule,
    ToastModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
      },
    }),
  ],
  providers: [
    PriorityService,
    MessageService,
    DialogService,
    ConfirmationService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [
        SharedUserService,
        FilterService,
        RegionService,
        CroptypeService,
        SettingsService,
        SharedConstantService,
        DirectionsNetworkService,
        HarvestOfferService,
        WebSocketsService,
        LoggerService,
        AuthService,
        PriorityService,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: TranslateLocalIdFactory,
      deps: [TranslateService],
    },
    OfferService,
    {
      provide: API_URL,
      useValue: environment.apiUrl,
    },
    OfferService,
    ToastService,
    LoggerService,
    AuthService,
    VersionService,
    TechniciansService,
    MonitoringService,
    {
      provide: SharedConstantService,
      useClass: ConstantService,
    },
    {
      provide: SharedPermissionService,
      useClass: PermissionService,
    },
    {
      provide: SharedUserService,
      useClass: UserService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
