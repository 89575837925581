import { LoggerService, SharedUserService } from '@agroone-front/shared'
import { SyncModel } from '@agroone/entities'
import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { BehaviorSubject, interval, Observable, of, throwError, timer } from 'rxjs'
import { catchError, delay, mergeMap, retryWhen, tap } from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'
import { FilterService } from '../filter/filter.service'

/** @deprecated('Use the new websocket service') */
export class WebSocketEvent {
  lotId: number
  action: 'realtime-form' | 'lot-menu'
  open: boolean

  constructor(webSocketEvent: WebSocketEvent) {
    this.open = webSocketEvent?.open ?? false
    this.action = webSocketEvent?.action ?? null
    this.lotId = webSocketEvent?.lotId ?? null
  }
}
/** @deprecated('Use the new websocket service') */
export class WebSocketData {
  user?: string
  region?: string
  date?: Date
  message?: string
  events?: WebSocketEvent
  update: SyncModel

  constructor(webSocketData: WebSocketData) {
    this.user = webSocketData?.user ?? null
    this.update = webSocketData?.update ?? null
    this.region = webSocketData?.region ?? ''
    this.message = webSocketData?.message ?? ''
    this.events = webSocketData?.events ? new WebSocketEvent(webSocketData.events) : null
    this.date = new Date()
  }
}
/* @deprecated('Use the new websocket service') */
export class WebSocketPing {
  connectionId: string
  requestId: string
  message: string

  constructor(webSocketPing: WebSocketPing) {
    this.connectionId = webSocketPing?.connectionId ?? ''
    this.requestId = webSocketPing?.requestId ?? ''
    this.message = webSocketPing?.message ?? ''
  }
}

export const APP_ID: string = 'harvest'

/** @deprecated('Use the new websocket service') */
@Injectable({
  providedIn: 'root',
})
export class WebSocketsService {
  public firstConnection: boolean = true

  public webSocket = webSocket({
    url: `${environment.webSocketUrl}`,
    deserializer: ({ data }) => {
      data = JSON.parse(data)
      if (data && data.connectionId) {
        return new WebSocketPing(data)
      }

      return new WebSocketData(data)
    },
    openObserver: {
      next: () => {
        this.identify()
        this.startPing()
        this.isConnectSubject.next(true)
        this.firstConnection = false
      },
      error: (err) => {
        this.logger.error(err)
      },
    },
    closeObserver: {
      next: () => {
        this.isConnectSubject.next(false)
      },
      error: (err) => {
        this.logger.error(err)
      },
    },
  })

  public isConnectSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isConnect$: Observable<boolean> = this.isConnectSubject.asObservable()

  get isConnect(): boolean {
    return this.isConnectSubject.getValue()
  }

  public eventsSubject: BehaviorSubject<WebSocketData[]> = new BehaviorSubject<WebSocketData[]>(null)
  public events$: Observable<WebSocketData[]> = this.eventsSubject.asObservable()
  get lotEvents(): WebSocketData[] {
    return this.eventsSubject.getValue()
  }

  public messageSubject: BehaviorSubject<WebSocketData> = new BehaviorSubject<WebSocketData>(null)
  public message$: Observable<WebSocketData> = this.messageSubject.asObservable()
  get messages(): WebSocketData {
    return this.messageSubject.getValue()
  }

  constructor(
    private userService: SharedUserService,
    private filterService: FilterService,
    private logger: LoggerService
  ) {
    interval(60000 * 110).subscribe(() => {
      if (this.isConnect) {
        of(this.disconnect())
          .pipe(delay(500))
          .subscribe(() => this.connect())
      }
    })
  }

  public connect(): void {
    if (!this.isConnect && !environment.local) {
      this.webSocket
        .pipe(
          retryWhen((err$) =>
            err$.pipe(
              tap(this.logger.error),
              mergeMap((err, i) => (i > 3 ? throwError('Error websocket retry!') : timer(10000)))
            )
          ),
          catchError(() => of('Error websocket retry!'))
        )
        .subscribe(
          (data: WebSocketData) => {
            this.logger.info('received websocket message : ', data)
            if (data && data.region === this.filterService.filters.region && !data.events) {
              this.messageSubject.next(data)
            }
            if (data.events) {
              const lotEvents = this.lotEvents
                ? this.lotEvents?.filter((d) => d.events.lotId !== data.events.lotId)
                : []
              lotEvents.push(data)
              this.eventsSubject.next(lotEvents)
            }
          },
          (e: CloseEvent) => {
            this.isConnectSubject.next(false)
            this.logger.error(e?.reason, e)
          }
        )
    }
  }

  public disconnect(): void {
    this.webSocket.complete()
  }

  public startPing() {
    interval(60000 * 8).subscribe(() =>
      this.webSocket.next({
        action: 'ping',
      } as any)
    )
  }

  public send(data: WebSocketData): void {
    data.user = this.userService.currentUser.email
    data.region = this.filterService.filters.region
    this.webSocket.next({
      action: 'send-message',
      data: JSON.stringify(data),
    } as any)
  }

  private identify(): void {
    this.webSocket.next({
      action: 'identify',
      application: APP_ID,
      user: this.userService.currentUser.email,
    } as any)
  }
}
