import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { from, Observable } from 'rxjs'
import { SaveSpecification, Specification } from '@agroone/entities'
import { NetworkService } from './network.service'
import { API_URL } from '../models/environment.token'

@Injectable({
  providedIn: 'root',
})
export class SpecificationsService {
  private _apiUrl: string = inject(API_URL)

  private specificationsUrl: string = `${this._apiUrl}/specifications`

  constructor(private http: HttpClient, private networkService: NetworkService) {}

  getAllSpecForActiveRegion(): Observable<Specification[]> {
    return from(this.networkService.getAllFromPaginated<Specification>(this.specificationsUrl))
  }

  add(specification: SaveSpecification): Observable<Specification> {
    return this.http.post<Specification>(this.specificationsUrl, specification)
  }

  update(specification: SaveSpecification): Observable<Specification> {
    return this.http.put<Specification>(this.specificationsUrl, specification)
  }

  delete(specificationId: number) {
    return this.http.delete(`${this.specificationsUrl}/${specificationId}`)
  }
}
