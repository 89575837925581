import { IsInt, IsNotEmpty, IsNumber, IsPositive, IsString } from 'class-validator'

export class CommentDAO {
  id?: number
  item: string
  subItem: string
  text: string
  dateBegin: string
  dateEnd: string
  color: string
  harvestTeamId: number
}

export class Comment {
  public id: number
  public harvestTeamId: number
  public item: string
  public subItem: string
  public text: string
  public dateBegin: string
  public dateEnd: string
  public color: string

  constructor(comment?: CommentDAO) {
    if (comment?.id) {
      this.id = comment?.id
    }
    this.harvestTeamId = comment?.harvestTeamId
    this.item = comment?.item
    this.subItem = comment?.subItem
    this.text = comment?.text
    this.dateBegin = comment?.dateBegin
    this.dateEnd = comment?.dateEnd
    this.color = comment?.color
  }
}

export class CommentV2 {
  id: number
  harvestTeamId: number
  type: {
    id: number
    name: string
  }
  startDate: Date
  endDate: Date
  comment: string
}

export class CommentV2Dto {
  @IsNotEmpty()
  @IsString()
  comment: string

  @IsNotEmpty()
  startDate: Date

  @IsNotEmpty()
  endDate: Date

  @IsNotEmpty()
  @IsNumber()
  harvestTeamId: number

  @IsNotEmpty()
  @IsInt()
  @IsPositive()
  typeId: number
}
