<div class="modal-box">
  <div class="modal-title"></div>
  <div class="modal-content">
    <p class="flex flex-col items-center justify-center">
      <ng-container *ngIf="isUpdateInProgress; else isNotUpdateInProgress">
        <div>{{ 'DIALOG.UPDATE.MESSAGE_IN_PROGRESS' | translate }}</div>
        <span class="loading loading-spinner loading-lg"></span>
      </ng-container>

      <ng-template #isNotUpdateInProgress>
        {{ 'DIALOG.UPDATE.MESSAGE' | translate }}
      </ng-template>
    </p>
  </div>
  <div class="modal-action">
    <button class="btn btn-primary" (click)="reload()">
      {{ 'DIALOG.UPDATE.RELOAD' | translate }}
    </button>
  </div>
</div>
